import React from "react";
import Helmet from "react-helmet";
import { Jumbotron } from "../components/Jumbotron";
import SEO from "../components/SEO";
import "../assets/css/App.css";
import "../assets/css/index.css";

import Layout from "../components/Layout";

export default () => (
  <Layout>
    <SEO
      title="Renewable Energy"
      description="Want to run your business on renewable energy? We can install and set up equipment, allowing you to use renewable energy sources, saving your business money"
      keywords="renewable energy london, renewable energy commercial heating london, renewable energy potters bar, renewable energy hertfordshire"
    />
    <Jumbotron imgClassName="img2"></Jumbotron>
    <div class="white-segment text-left">
      <h1 class="heading-color">Reducing your carbon emissons CO2</h1>
      <p>
        With the increasingly worrying problem of climate change, some people
        are turning to a more environmentally friendly way of producing their
        heating and hot water, which will produce a much lower, or zero amount
        of the greenhouse effect gas.
      </p>
      <h3 class="">What is renewable energy?</h3>
      <p>
        Natural resources such as the sun, wind and water are used to
        continuously generate energy. This energy cannot be exhausted and is
        constantly renewed. Installing renewables should reduce your energy
        bills, and reduce your carbon emissions.
      </p>
      <p>
        All renewable heating sources efficiency will depend on how energy
        efficient your premises are. You may benefit from additional work before
        the system is installed.
      </p>
    </div>
  </Layout>
);
